import * as styles from "./GetWizrBanner.module.scss";
import React from "react";
function GetWizrBanner({image,extraClass,title,description,logo}){
return(
    <div className={`${styles.container} ${styles[extraClass]}`}>
        <div className={styles.content}>
            {logo && <img alt="logo" src={logo} />}
            {title && <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>}
            <p  dangerouslySetInnerHTML={{ __html: description }}></p>
        </div>
        <img alt="banner" className={styles.image} src={image} />
    </div>
);
}
export default GetWizrBanner;
