import React from "react";
import * as styles from "./wizrsection.module.scss";
function Wizrsection() {
  return (
    <div className={styles.container}>
    <div className={styles.content}>
    <h2>
        <span>Get WiZR</span> - 
        How to use MASTER WiZR products
      </h2>
      <p>
        <span>• Show Room:</span> multimedia page with articles, videos,
        presentations and manuals
      </p>
      <p>
        <span>• Explorer videos:</span> take inspirations from videos matching
        your situations and needs
      </p>
      <p>
        <span>• Licenses:</span> see different user options and brief videos
      </p>
    </div>
    </div>
  );
}
export default Wizrsection;
