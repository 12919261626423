import React from "react";
import GetWizrBanner from "../components/atoms/getwizrBanner/GetWizrBanner";
import GetWiZRHeader from "../components/atoms/Getwizrheader";
import image1 from "../images/wizrscreen1.jpg";
import image2 from "../images/gridscreen1.png";
import image3 from "../images/wizrscreen4.jpg";
import image5 from "../images/wizrscreen11.png";


import getwizrlogo from "../images/whitewizr.png";
import Divider from "../components/atoms/Divider/Divider";
import Grid from "../components/molecules/Grid/Grid";
import Getwizrfooter from "../components/atoms/GetWizrFooter/Getwizrfooter";
import Wizrsection from "../components/molecules/wizrSection/wizrsection";

function GetWizr(){
    return(
        <>
        <GetWiZRHeader />
        <GetWizrBanner extraClass={"firstbanner"} logo={getwizrlogo} image={image1} description={"Connect - Stand out - Optimize Your Message"}/>
        <Wizrsection />
        <GetWizrBanner extraClass={"secondbanner"} image={image2} title={"Customize and Connect"} description={"Presentations in system. Express yourself"}/>
        <GetWizrBanner extraClass={"thirdbanner"}  image={image3} title={"Present Like A Pro "} description={"Studio - Amazing templates - Easy editing "}/>
        <Divider text={`Re-imagine your content with tools that will make your material shine.` } extraClass="largeFont" />
        <Grid />
        <GetWizrBanner extraClass={"fourthbanner"}  image={image5} title={`Unlock Your <br/> Communications`} description={`Maximize your potential`}/>
        <Getwizrfooter />
         </>
    );
}
export default GetWizr;