import React from "react";
import * as styles from "./Divider.module.scss";

function Divider({text,extraClass}){
    return(
        <div className={`${styles.container} ${styles[extraClass]}`}>
         <h2  dangerouslySetInnerHTML={{ __html: text }}></h2>
        </div>
    );
}
export default Divider;