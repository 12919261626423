// extracted by mini-css-extract-plugin
export var container = "GetWizrBanner-module--container--ed3bd";
export var content = "GetWizrBanner-module--content--17198";
export var firstbanner = "GetWizrBanner-module--firstbanner--a501a";
export var fourthbanner = "GetWizrBanner-module--fourthbanner--fb685";
export var gridbanner = "GetWizrBanner-module--gridbanner--d7e28";
export var gridbannergreen = "GetWizrBanner-module--gridbannergreen--7b3c6";
export var gridbannerright = "GetWizrBanner-module--gridbannerright--67c2e";
export var gridbannerwhite = "GetWizrBanner-module--gridbannerwhite--a2f31";
export var image = "GetWizrBanner-module--image--b305f";
export var secondbanner = "GetWizrBanner-module--secondbanner--c525b";
export var thirdbanner = "GetWizrBanner-module--thirdbanner--61a74";