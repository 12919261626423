import React from "react";
import * as styles from "./Grid.module.scss";
import image1 from "../../../images/wizywizr1.jpg";
import image2 from "../../../images/wizrscreen10.png";
import image3 from "../../../images/wizywizr2.jpg";
import image4 from "../../../images/wizrscreen9.png";

import GetWizrBanner from "../../atoms/getwizrBanner/GetWizrBanner";
function Grid(){
    return(
        <div className={styles.container}>
        <GetWizrBanner extraClass={"gridbannerwhite"} image={image4} title={"Fully integrated solutions "} description={"Our WiZR Connect get its own super functional dashboard"}/>
        <GetWizrBanner extraClass={"gridbannerright"} image={image2} title={"Express Yourself"} description={"Unlimited ways of using our universal tools"}/>
        <GetWizrBanner extraClass={"gridbanner"} image={image3} title={"A touch of the future now "} description={"The unique feeling of being the first to seeing the next big thing "}/>
        <GetWizrBanner extraClass={"gridbannergreen"} image={image1} title={"Finding peace with All-in-One "} description={"Our vision is to creating the ultimate work space "}/>
        </div>
    );
}
export default Grid;